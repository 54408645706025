const buildTitle = (myParam) => {
  const dataTitle = myParam.split(",")
  let title = ""

  if (dataTitle.length > 1) {
    title = `${dataTitle[0].toUpperCase()} vs ${dataTitle[1].toUpperCase() }`
    title = dataTitle.slice(0, dataTitle.length).map(title => title.toUpperCase()).join(" vs ");
  } else {
    title = `${dataTitle[0].toUpperCase()}`
  }

  return title
}
const configChart = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const titleParam = urlParams.get('stock_labels');

  const durationParam = urlParams.get('duration');

  const config_dict = {
    "title": `${buildTitle(titleParam)}`,
    "subtitle": "Gain",
    "subtitleEnabled": false,
    "legendEnabled": true,
    "tickInterval_y": 10,
    "formatter": false,
    "dateFormat": "%Y-%m-%d",
    "formatter_value": "",
    "symbole_value": " %",
    "min_range": 30 * 24 * 3600 * 1000,
    "tickInterval_x": 30 * 24 * 3600 * 1000,
    "axe_y": "Base 100",
    "position_horizontal_label_axe_y":65,
    "position_vertical_label_axe_y": -20,
    "axe_x": "",
    "source": "Source: Yahoo Finance",
    "duration": parseInt(durationParam),
  }

  return config_dict

  return config_dict
}

export { configChart }
